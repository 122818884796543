// import * as THREE from "./three.js-r132/build/three.module.js";
import * as THREE from "three";

export const createChromaMaterial = (texture, keyColor) => {
  const keyColorObject = new THREE.Color(keyColor);
  const material = new THREE.ShaderMaterial({
    uniforms: {
      tex: {
        type: "t",
        value: texture,
      },
      color: {
        type: "c",
        value: keyColorObject,
      },
    },
    vertexShader:
      "varying mediump vec2 vUv;\n" +
      "void main(void)\n" +
      "{\n" +
      "vUv = uv;\n" +
      "mediump vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );\n" +
      "gl_Position = projectionMatrix * mvPosition;\n" +
      "}",
    fragmentShader:
      "uniform mediump sampler2D tex;\n" +
      "uniform mediump vec3 color;\n" +
      "varying mediump vec2 vUv;\n" +
      "void main(void)\n" +
      "{\n" +
      "  mediump vec3 tColor = texture2D( tex, vUv ).rgb;\n" +
      "  mediump float a = (length(tColor - color) - 0.7) * 7.0;\n" +
      "  gl_FragColor = vec4(tColor, a);\n" +
      "}",
    transparent: true,
  });
  return material;
};
