import { loadVideo } from "./assets/loader";
import { createChromaMaterial } from "./assets/chroma-video";
import { useEffect, useState } from "react";
import mindFile from "./assets/bold.mind";
import diamandis from "./assets/diamandis1.mov";
const THREE = window.MINDAR.IMAGE.THREE;

function App() {
  const [isPlaying, setIsPlaying] = useState(false);

  async function handleClick() {
    const mindarThree = new window.MINDAR.IMAGE.MindARThree({
      container: document.body,
      imageTargetSrc: mindFile,
    });
    const { renderer, scene, camera } = mindarThree;

    let video = null;
    video = await loadVideo(diamandis);

    const texture = new THREE.VideoTexture(video);
    const geometry = new THREE.PlaneGeometry(1, 1080 / 1920);
    const material = createChromaMaterial(texture, 0x00ff00);
    const plane = new THREE.Mesh(geometry, material);
    plane.rotation.x = Math.PI / 2;
    plane.position.y = 0.7;
    plane.scale.multiplyScalar(4);
    const anchor = mindarThree.addAnchor(0);
    anchor.group.add(plane);

    anchor.onTargetFound = () => {
      if (!isPlaying) {
        video.play();
        setIsPlaying(true);
      }
    };
    anchor.onTargetLost = () => {
      video.pause();
    };
    video.addEventListener("play", () => {
      video.currentTime = 6;
    });

    await mindarThree.start();
    renderer.setAnimationLoop(() => {
      renderer.render(scene, camera);
    });
  }

  return (
    <div>
      {!isPlaying && (
        <button style={{ padding: "20px" }} onClick={handleClick}>
          Start AR
        </button>
      )}
      {isPlaying && <button>Stop AR</button>}
    </div>
  );
}

export default App;
